<template>
  <later-section :class="{ 'bg-white': !inverted, 'bg-gray-light': inverted }">
    <div
      :class="{ 'flex-row-reverse': inverted }"
      class="flex flex-wrap md:flex-no-wrap"
    >
      <div class="md:w-3/5 p-12">
        <h2 class="text-4xl">
          <slot name="headline" />
        </h2>
        <p class="pt-4 leading-loose text-xl font-light">
          <slot name="copy" />
        </p>
      </div>
      <div class="md:w-2/5 p-8 w-full text-center">
        <slot name="image" />
      </div>
    </div>
  </later-section>
</template>

<script>
import LaterSection from './section.vue';

export default {
  components: {
    LaterSection,
  },

  props: {
    inverted: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

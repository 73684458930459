<style lang="scss" scoped>
.hero {
  background-image: url('../../img/home/geometric-color.png');
}
.composer {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../../img/home/dude-with-phone.jpg');
}
</style>

<template>
  <div>
    <later-section class="hero bg-cover">
      <div class="text-center py-8">
        <h1
          class="text-4xl sm:text-5xl text-white pb-8"
          style="text-shadow: 1px 1px 5px gray"
        >
          Write emails now &mdash;<br />
          We send them ... later!
        </h1>
        <div class="pt-8">
          <mdc-button raised @click.native="$router.push('/register')">
            Try it for free
          </mdc-button>
        </div>
      </div>
    </later-section>

    <later-section-info>
      <template slot="headline"> Send emails when it works for you </template>
      <template slot="copy">
        Write emails when you want, schedule them for delivery exactly when you
        want them to arrive. You can comfortably write emails when you want and
        make it look like you are burning the midnight oil.
      </template>
      <img slot="image" src="@/img/home/schedule.png" />
    </later-section-info>

    <later-section-info inverted class="composer bg-cover text-white">
      <template slot="headline"> Recurring reminders are key </template>
      <template slot="copy">
        Easily remind yourself or your co-workers about the reports that are
        nearly due. Remind yourself of a habit you want to establish or a
        payment that you need to make.
      </template>
      <img slot="image" src="@/img/home/recurring.png" />
    </later-section-info>

    <later-section-info>
      <template slot="headline"> Works with any email address </template>
      <template slot="copy">
        <p>
          Write emails from your own inbox. Even have your email provider
          <i>send</i> the emails as well (if it supports SMTP).
        </p>
        <mdc-button @click.native="$router.push('/faq')"> See FAQ </mdc-button>
      </template>
      <img slot="image" src="@/img/home/email-arrows.png" />
    </later-section-info>

    <!-- <later-section-info inverted>
      <template slot="headline">
        Post to Facebook
      </template>
      <template slot="copy">
        If social media is your jam, we can simplify your life by allowing
        you to coordinate your Facebook posting.  You can schedule social
        posts when they will have the greatest reach.
      </template>
      <img slot="image" src="@/img/home/bullhorn.png">
    </later-section-info> -->

    <section class="shadow-inner py-12">
      <later-plans class="max-w-screen-xl mx-auto pb-12" />
      <div class="w-full text-center py-6">
        <mdc-button raised secondary @click.native="$router.push('/register')">
          Sign Up!
        </mdc-button>
      </div>
    </section>
  </div>
</template>

<script>
import LaterPlans from '@/components/plans.vue';
import MdcButton from '@/components/mdc/button.vue';
import LaterSection from './partials/section.vue';
import LaterSectionInfo from './partials/section-info.vue';

export default {
  components: {
    LaterPlans,
    LaterSection,
    LaterSectionInfo,
    MdcButton,
  },
};
</script>
